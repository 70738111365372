import React from "react";
import PropTypes from "prop-types";

function SinglePortfolio(props) {
  return (
    <div className="w-full bg-gray-200 shadow-lg card shadow-slate-700 ">
      <figure>
        <img src={props.imageSource} alt={props.alt} loading="lazy" />
      </figure>
      <div className="card-body max-h-fit">
        <h2 className="card-title">{props.title}</h2>
        <p>{props.description}</p>
        <div className="justify-end mt-4 card-actions">
          <a title={props.title} target="_blank" rel="noopner noreferrer" href={props.btnLink}>
            <button className="btn btn-primary">{props.btnText}</button>
          </a>
        </div>
      </div>
    </div>
  );
}

SinglePortfolio.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSource: PropTypes.string.isRequired,
};

SinglePortfolio.defaultProps = {
  alt: "Projects Made By GrupyStar",
  btnText: "See The Website",
  btnLink: "#",
};

export default SinglePortfolio;
