import React from 'react';
import SingleOurTech from '../components/SingleOurTech';
import { motion } from 'framer-motion';
import { ourTechnologiesSectionAnimation } from '../animateProperties';

import PlusSignIcon from '../components/PlusSignIcon';
import DoubleTribgleIcon from '../components/DoubleTringleIcon';

import { ReactComponent as Html } from '../assets/svg/logos/html-5-logo.svg';
import { ReactComponent as Css } from '../assets/svg/logos/css-logo.svg';
import { ReactComponent as Js } from '../assets/svg/logos/js-logo.svg';
import { ReactComponent as Bootstrap } from '../assets/svg/logos/bootstrap-logo.svg';
import { ReactComponent as Tailwind } from '../assets/svg/logos/tailwindcss-logo.svg';
import { ReactComponent as Reactjs } from '../assets/svg/logos/react-logo.svg';

import { ReactComponent as Csharp } from '../assets/svg/logos/csharp-logo.svg';
import { ReactComponent as DotNetCore } from '../assets/svg/logos/dot-net-core-logo.svg';
import { ReactComponent as MySql } from '../assets/svg/logos/mysql-logo.svg';
import { ReactComponent as Blazor } from '../assets/svg/logos/blazor-logo.svg';
import { ReactComponent as Azure } from '../assets/svg/logos/azure-logo.svg';

import { ReactComponent as Xd } from '../assets/svg/logos/xd-logo.svg';
import { ReactComponent as WordPress } from '../assets/svg/logos/wordpress-logo.svg';
import { ReactComponent as Elementor } from '../assets/svg/logos/elementor-logo.svg';
import { ReactComponent as WooCommerce } from '../assets/svg/logos/woocommerce-logo.svg';

function OurTechnologiesSection() {
  const svgSize = 110;

  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} id="ourTechnologies" className="min-h-screen py-16 animate-bg">
      <div className="container mx-auto ">
        <div>
          <div className="relative invisible lg:visible">
            <div className="absolute top-2 left-36">
              <PlusSignIcon />
            </div>
            <div className="absolute -rotate-12 top-12 right-24">
              <DoubleTribgleIcon width={35} height={35} />
            </div>
          </div>
          <motion.h1 variants={ourTechnologiesSectionAnimation.technologiesTitleV} className="mb-8 text-4xl font-bold text-center text-white md:mb-12 md:text-5xl">
            The Technologies We Use
          </motion.h1>
        </div>
        <motion.div
          variants={ourTechnologiesSectionAnimation.parentItemV}
          initial="initial"
          whileInView="whileInView"
          viewport={{ once: true }}
          className="grid grid-cols-2 mt-28 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 gap-y-12"
        >
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Html width={svgSize} height={svgSize} />} title="HTML" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Css width={svgSize} height={svgSize} />} title="CSS" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Js width={svgSize} height={svgSize} />} title="JavaScript" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Bootstrap width={svgSize} height={svgSize} />} title="Bootstrap" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Tailwind width={svgSize} height={svgSize} />} title="Tailwind CSS" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Reactjs width={svgSize} height={svgSize} />} title="React JS" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Csharp width={svgSize} height={svgSize} />} title="C#" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<DotNetCore width={svgSize} height={svgSize} />} title=".Net Core" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<MySql width={svgSize} height={svgSize} />} title="MySQL" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Blazor width={svgSize} height={svgSize} />} title="Blazor" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Azure width={svgSize} height={svgSize} />} title="Microsoft Azure" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Xd width={svgSize} height={svgSize} />} title="Adobe XD" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<WordPress width={svgSize} height={svgSize} />} title="WordPress" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<WooCommerce width={svgSize} height={svgSize} />} title="WooCommerce" />
          </motion.div>
          <motion.div variants={ourTechnologiesSectionAnimation.itemV}>
            <SingleOurTech source={<Elementor width={svgSize} height={svgSize} />} title="Elementor" />
          </motion.div>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default OurTechnologiesSection;
