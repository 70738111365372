import React from "react";
import { motion } from "framer-motion";

function PlusSignIcon({ width = "22.123", height = "22.123" }) {
  const plusVariants = {
    initial: {
      rotate: 0,
    },
    animate: {
      rotate: 180,
      transition: {
        duration: 1,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
        ease: "linear",
      },
    },
  };

  return (
    <motion.svg
      variants={plusVariants}
      initial="initial"
      animate="animate"
      drag
      dragConstraints={{
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path
        id="Path_4"
        data-name="Path 4"
        d="M17.036,5.733a1.383,1.383,0,0,1,1.383,1.383v8.3h8.3a1.383,1.383,0,1,1,0,2.765h-8.3v8.3a1.383,1.383,0,1,1-2.765,0v-8.3h-8.3a1.383,1.383,0,0,1,0-2.765h8.3v-8.3A1.383,1.383,0,0,1,17.036,5.733Z"
        transform="translate(-5.974 -5.733)"
        fill="#e7e7e7"
      />
    </motion.svg>
  );
}

export default PlusSignIcon;
