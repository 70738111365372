import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import GrupyStarLogo from '../assets/logo/GrupyStar_Logo_x1.png';
import { Spin as Hamburger } from 'hamburger-react';
import { motion } from 'framer-motion';
import { headerAnimation } from '../animateProperties';
import DoubleCircleIcon from './DoubleCircleIcon';

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const handleNav = () => {
    document.querySelector('#menu-id').classList.toggle('active');
    setIsOpen(!isOpen);
  };

  return (
    <motion.header initial="initial" whileInView="whileInView" viewport={{ once: true }} className="px-4 md:px-4 lg:px-20 xl:px-32">
      <div className="font-medium bg-transparent items-center navbar text-base-100 h-[80px]">
        <motion.div variants={headerAnimation.logoV} className="flex-1">
          <Link to="/" className="btn btn-ghost hover:scale-110 h-[80px]">
            <img src={GrupyStarLogo} alt="GrupyStar Logo" />
          </Link>
        </motion.div>
        {/* Nav For Computer */}
        <div className="flex-none hidden md:block">
          <motion.ul variants={headerAnimation.parentLiV} initial="initial" whileInView="whileInView" viewport={{ once: true }} className="p-0 menu menu-horizontal">
            <motion.li variants={headerAnimation.liV}>
              <Link className="hover:text-primary hover:scale-110 focus:text-white" to="/">
                Home
              </Link>
            </motion.li>
            <motion.li variants={headerAnimation.liV}>
              <HashLink className="hover:text-primary hover:scale-110 focus:text-white" to="/#services">
                Services
              </HashLink>
            </motion.li>
            <motion.li variants={headerAnimation.liV}>
              <HashLink className="hover:text-primary hover:scale-110 focus:text-white" to="/#ourTechnologies">
                Technologies
              </HashLink>
            </motion.li>
            <motion.li variants={headerAnimation.liV}>
              <Link className="hover:text-primary hover:scale-110 focus:text-white" to="/portfolios">
                Portfolios
              </Link>
            </motion.li>
            <motion.li variants={headerAnimation.liV}>
              <Link className="hover:text-primary hover:scale-110 focus:text-white" to="/contact">
                Contact
              </Link>
            </motion.li>
          </motion.ul>
        </div>
        {/* Nav For Mobile */}
        <motion.div variants={headerAnimation.hamburgerV} onClick={handleNav} className="z-10 flex-none block cursor-pointer md:hidden">
          <Hamburger toggled={isOpen} toggle={setIsOpen} rounded />
        </motion.div>
        <div id="menu-id" className="m-0 p-0 fixed top-0 -left-full w-full h-full animate-bg z-[1] duration-500">
          <motion.ul
            variants={headerAnimation.parentMobileLiV}
            initial="initial"
            whileInView="whileInView"
            className="absolute p-0 m-0 text-center -translate-x-1/2 -translate-y-1/2 select-none top-1/2 left-1/2"
          >
            <li>
              <div className="absolute -left-8 -top-16">
                <DoubleCircleIcon />
              </div>
            </li>
            <motion.li variants={headerAnimation.liV} className="my-6 list-none" onClick={handleNav}>
              <Link className="p-2 text-2xl text-white uppercase rounded-lg hover:bg-primary hover:scale-110 hover:text-white" to="/">
                Home
              </Link>
            </motion.li>
            <motion.li variants={headerAnimation.liV} className="my-6 list-none" onClick={handleNav}>
              <HashLink className="p-2 text-2xl text-white uppercase rounded-lg hover:bg-primary hover:scale-110 hover:text-white" to="/#services">
                Services
              </HashLink>
            </motion.li>
            <motion.li variants={headerAnimation.liV} className="my-6 list-none" onClick={handleNav}>
              <HashLink className="p-2 text-2xl text-white uppercase rounded-lg hover:bg-primary hover:scale-110 hover:text-white" to="/#ourTechnologies">
                Technologies
              </HashLink>
            </motion.li>
            <motion.li variants={headerAnimation.liV} className="my-6 list-none" onClick={handleNav}>
              <Link className="p-2 text-2xl text-white uppercase rounded-lg hover:bg-primary hover:scale-110 hover:text-white" to="/portfolios">
                Portfolios
              </Link>
            </motion.li>
            <motion.li variants={headerAnimation.liV} className="my-6 list-none" onClick={handleNav}>
              <Link className="p-2 text-2xl text-white uppercase rounded-lg hover:bg-primary hover:scale-110 hover:text-white" to="/contact">
                Contact
              </Link>
            </motion.li>
          </motion.ul>
        </div>
      </div>
    </motion.header>
  );
}

export default Header;
