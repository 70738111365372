import React from "react";
import { motion } from "framer-motion";

function DoubleTribgleIcon({ width = 40, height = 55, delay1 = 0, delay2 = 0.4 }) {
  const triangleV1 = {
    initial: {
      rotate: 0,
      pathLength: 0,
    },
    animate: {
      pathLength: 1,
      transition: {
        delay: delay1,
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: 0.5,
        ease: "easeInOut",
      },
    },
  };

  const triangleV2 = {
    initial: {
      rotate: 0,
      pathLength: 0,
    },
    animate: {
      pathLength: 1,
      transition: {
        delay: delay2,
        repeatType: "reverse",
        duration: 1.5,
        repeat: Infinity,
        repeatDelay: 0.5,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      drag
      dragConstraints={{
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      }}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 40.16 54.898"
    >
      <g id="Group_2" data-name="Group 2" transform="translate(-20.53 -12.387)">
        <motion.path
          variants={triangleV1}
          initial="initial"
          animate="animate"
          id="Path_2"
          data-name="Path 2"
          d="M21.525,51.882l.9-38.5,32.891,20.03Z"
          fill="none"
          stroke="#e7e7e7"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <motion.path
          variants={triangleV2}
          initial="initial"
          animate="animate"
          id="Path_3"
          data-name="Path 3"
          d="M24.549,66.953l.955-40.8L60.358,47.382Z"
          fill="none"
          stroke="#e7e7e7"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </g>
    </motion.svg>
  );
}

export default DoubleTribgleIcon;
