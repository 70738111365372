const heroSectionAnimation = {
  titleV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.2 } },
  },
  taglineV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.4 } },
  },
  serviceBtnV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.6 } },
  },
  heroVectorV: {
    initial: { x: "100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.5, delay: 0.3 } },
  },
};

const littleContactSectionAnimation = {
  headV: {
    initial: { x: "-75px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  paragraphV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8, delay: 0.3 } },
  },
  btnV: {
    initial: { x: 75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
};

const ourClientsSectionAnimation = {
  clientsTitleV: {
    initial: { x: "-75px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  parentItemV: {
    whileInView: { transition: { staggerChildren: 0.25 } },
  },
  itemV: {
    initial: { y: 150, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 1 } },
  },
};

const ourTechnologiesSectionAnimation = {
  technologiesTitleV: {
    initial: { x: 75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  parentItemV: {
    whileInView: { transition: { staggerChildren: 0.15 } },
  },
  itemV: {
    initial: { scale: 0, opacity: 0 },
    whileInView: { scale: 1, opacity: 1, transition: { duration: 1 } },
  },
};

const servicesSectionAnimation = {
  serviceTitleV: {
    initial: { y: 75, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  parentItemV: {
    whileInView: { transition: { staggerChildren: 0.25 } },
  },
  itemV: {
    initial: { y: 75, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 1 } },
  },
};

const headerAnimation = {
  logoV: {
    initial: { x: -75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  hamburgerV: {
    initial: { x: 75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
  parentLiV: {
    whileInView: { transition: { staggerChildren: 0.1 } },
  },
  parentMobileLiV: {
    whileInView: { transition: { staggerChildren: 0.2 } },
  },
  liV: {
    initial: { y: -20, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 0.5 } },
  },
};

const footerAnimation = {
  parentBtnV: {
    whileInView: { transition: { staggerChildren: 0.3 } },
  },
  btnV: {
    initial: { y: 75, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 0.7 } },
  },
  parentIconV: {
    whileInView: { transition: { staggerChildren: 0.5 } },
  },
  icon1V: {
    initial: { x: -75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8, delay: 0.2 } },
  },
  icon2V: {
    initial: { y: 75, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 0.8, delay: 0.4 } },
  },
  icon3V: {
    initial: { x: 75, opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8, delay: 0.6 } },
  },
  paragraphV: {
    initial: { x: "-75px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.8 } },
  },
};

const portfoliosPageAnimation = {
  portfoliosTitleV: {
    initial: { y: 75, opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 1 } },
  },
  plusSignIconV: {
    initial: { opacity: 0 },
    animate: { opacity: 1, transition: { duration: 1 } },
  },
  parentPortfoliosV: {
    whileInView: { transition: { staggerChildren: 0.4, when: "beforeChildren" } },
  },
  portfoliosLeftV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 1 } },
  },
  portfoliosRightV: {
    initial: { x: "100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 1 } },
  },
};

const contactPageAnimation = {
  formTitleV: {
    initial: { x: "-100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.7 } },
  },
  parentFormFieldsV: {
    whileInView: { transition: { staggerChildren: 0.3 } },
  },
  formFieldsV: {
    initial: { x: "-75px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.7 } },
  },
  detailsTitleV: {
    initial: { x: "100px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.7, delay: -0.5 } },
  },
  parentDetailsV: {
    whileInView: { transition: { staggerChildren: 0.4, when: "beforeChildren" } },
  },
  detailsV: {
    initial: { x: "75px", opacity: 0 },
    whileInView: { x: 0, opacity: 1, transition: { duration: 0.7 } },
  },
};

const error404Page = {
  text404V: {
    initial: { y: "75px", opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 0.7 } },
  },
  taglineError404V: {
    initial: { y: "50px", opacity: 0 },
    whileInView: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.3 } },
  },
};

export {
  heroSectionAnimation,
  littleContactSectionAnimation,
  ourClientsSectionAnimation,
  ourTechnologiesSectionAnimation,
  servicesSectionAnimation,
  headerAnimation,
  footerAnimation,
  portfoliosPageAnimation,
  contactPageAnimation,
  error404Page,
};
