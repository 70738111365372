import React from "react";

function SingleClient(props) {
  return (
    <div className="mx-auto transition-transform opacity-70 hover:opacity-100 hover:scale-110">
      <div className="w-52 lg:w-40">
        <img className="" src={props.source} alt={props.alt} />
      </div>
    </div>
  );
}

export default SingleClient;
