import React, { useRef, useEffect } from "react";
import { motion } from "framer-motion";
import { contactPageAnimation } from "../animateProperties";
import DoubleCircleIcon from "../components/DoubleCircleIcon";

import { ReactComponent as PhoneIcon } from "../assets/svg/phone.svg";
import { ReactComponent as EmailIcon } from "../assets/svg/email.svg";
import { ReactComponent as LocationIcon } from "../assets/svg/location.svg";
import emailjs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.min.css";
import { ToastContainer, toast } from "react-toastify";

function Contact() {
  useEffect(() => {
    document.title = "Contact | GrupyStar Company";
  }, []);

  const form = useRef();

  const sendEmails = (e) => {
    e.preventDefault();

    emailjs.sendForm("service_z6u88za", "template_no81nvo", form.current, "VF7-7zXLc3fC1PSPJ").then(
      (result) => {
        toast.success("Your message sent successfully.");
        e.target.reset();
      },
      (error) => {
        toast.error("Something went wrong, please try later.");
      }
    );
  };

  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} className="min-h-screen py-16 animate-bg">
      <div className="container grid grid-cols-1 mx-auto md:grid-cols-2 gap-x-8">
        <div>
          <div className="relative">
            <div className="absolute top-0 invisible right-16 lg:visible">
              <DoubleCircleIcon />
            </div>
            <motion.h2 variants={contactPageAnimation.formTitleV} className="my-8 text-2xl font-bold text-center text-white md:text-left md:my-12 md:text-3xl">
              What's Your Message?
            </motion.h2>
          </div>
          <motion.form ref={form} className="caret-primary" variants={contactPageAnimation.parentFormFieldsV} onSubmit={sendEmails}>
            <motion.div variants={contactPageAnimation.formFieldsV} className="mt-8">
              <label className="block mb-1 text-lg text-gray-300" htmlFor="name">
                Your Name
              </label>
              <input required="required" id="name" name="user_name" type="text" placeholder="Your Name" className="w-full input focus:outline-none input-primary md:max-w-md" />
            </motion.div>
            <motion.div variants={contactPageAnimation.formFieldsV} className="mt-8">
              <label className="block mb-1 text-lg text-gray-300" htmlFor="email">
                Your Email
              </label>
              <input required="required" name="user_email" id="email" type="email" placeholder="Your Email" className="w-full input focus:outline-none input-primary md:max-w-md" />
            </motion.div>
            <motion.div variants={contactPageAnimation.formFieldsV} className="mt-8">
              <label className="block mb-1 text-lg text-gray-300" htmlFor="message">
                Your Message
              </label>
              <textarea
                required="required"
                name="user_message"
                id="message"
                className="w-full textarea focus:outline-none textarea-primary md:max-w-md"
                placeholder="What do you want to deliver to us?"
              ></textarea>
            </motion.div>
            <motion.div variants={contactPageAnimation.formFieldsV}>
              <input className="mt-16 btn btn-primary btn-block md:max-w-md" type="submit" value="Send" />
            </motion.div>
          </motion.form>
        </div>
        <div className="mt-12 md:mt-0">
          <motion.h2 variants={contactPageAnimation.detailsTitleV} className="my-8 text-2xl font-bold text-center text-white md:text-left md:my-12 md:text-3xl">
            Contact Us Through:
          </motion.h2>
          <motion.div variants={contactPageAnimation.parentDetailsV}>
            <motion.div variants={contactPageAnimation.detailsV} className="flex items-center justify-center my-8 md:justify-start">
              <span className="transition-transform hover:scale-110">
                <EmailIcon width={35} className="mr-8 text-white transition-colors fill-current hover:text-primary" />
              </span>
              <a title="GrupyStar Email" href="mailto:grupystar@gmail.com" rel="noopner noreffer" className="text-xl text-white underline hover:text-primary">
                GrupyStar@gmail.com
              </a>
            </motion.div>
            <motion.div variants={contactPageAnimation.detailsV} className="flex items-center justify-center my-8 md:justify-start">
              <span className="transition-transform hover:scale-110">
                <PhoneIcon width={35} className="mr-8 text-white transition-colors fill-current hover:text-primary" />
              </span>
              <a title="GrupyStar Phone Number" href="tel:009647832194529" rel="noopner noreffer" className="text-xl text-white underline hover:text-primary">
                +964 783 219 45 29
              </a>
            </motion.div>
            <motion.div variants={contactPageAnimation.detailsV} className="flex items-center justify-center my-8 md:justify-start">
              <span className="transition-colors hover:scale-110">
                <LocationIcon width={35} className="mr-8 text-white transition-colors fill-current hover:text-primary" />
              </span>
              <p className="text-xl text-white hover:text-primary">We are online!</p>
            </motion.div>
          </motion.div>
        </div>
      </div>
      <ToastContainer autoClose={2500} />
    </motion.section>
  );
}

export default Contact;
