import React from "react";

function SingleOurTech(props) {
  return (
    <div className="mx-auto text-center transition-all opacity-90 hover:opacity-100 hover:scale-110 hover:rotate-3">
      {props.source}
      <h1 className="mt-4 text-2xl font-medium text-white select-none">{props.title}</h1>
    </div>
  );
}

export default SingleOurTech;
