import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { error404Page } from "../animateProperties";

function Error404() {
  useEffect(() => {
    document.title = "Error 404 | GrupyStar Company";
  }, []);

  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} className="items-center justify-center flex min-h-[80vh] animate-bg">
      <div>
        <motion.h2 variants={error404Page.text404V} className="-mt-8 align-text-top font-bold text-center text-[175px] sm:text-[250px] text-gray-200 leading-tight">
          404
        </motion.h2>
        <motion.p variants={error404Page.taglineError404V} className="-mt-4 text-base text-center text-gray-300 md:-mt-8 sm:text-xl">
          Sorry, We couldn't find what you are searching for.
        </motion.p>
      </div>
    </motion.section>
  );
}

export default Error404;
