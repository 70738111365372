import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { portfoliosPageAnimation } from "../animateProperties";
import DoubleTribgleIcon from "../components/DoubleTringleIcon";
import PlusSignIcon from "../components/PlusSignIcon";
import SinglePortfolio from "../components/SinglePortfolio";
import AmirFialaWebsite from "../assets/images/portfolios/amirfiala-website.jpeg";
import SalamMosque from "../assets/images/portfolios/salam-mosque.jpg";
import LibraryManager from "../assets/images/portfolios/Library-Manager.jpg";
import SweetRuya from "../assets/images/portfolios/Ruya-Sweet-Factory.jpeg";
import { Link } from "react-router-dom";

function Portfolios() {
  useEffect(() => {
    document.title = "Portfolios | GrupyStar Company";
  }, []);

  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} className="container min-h-screen py-16 mx-auto">
      <div className="mb-10 md:mb-24">
        <div className="relative invisible lg:visible">
          <div className="absolute top-4 left-24">
            <DoubleTribgleIcon delay1={0.8} delay2={1.2} />
          </div>
          <motion.div variants={portfoliosPageAnimation.plusSignIconV} initial="initial" animate="animate" className="absolute -rotate-45 top-16 right-24">
            <PlusSignIcon />
          </motion.div>
        </div>
        <motion.h1 variants={portfoliosPageAnimation.portfoliosTitleV} className="mb-8 text-4xl font-bold text-center text-white md:mb-12 md:text-5xl">
          Some Of Our Works
        </motion.h1>
      </div>
      <motion.div variants={portfoliosPageAnimation.parentPortfoliosV} className="grid grid-flow-row grid-cols-1 gap-x-6 gap-y-12 md:grid-cols-2 ">
        <motion.div variants={portfoliosPageAnimation.portfoliosLeftV}>
          <SinglePortfolio
            imageSource={SalamMosque}
            alt="Salam Mosque Website Screenshot"
            title="Salam Mosque"
            description="A Muslim contacted us in Tønsberg in Norway, they wanted a website for their mosque to get supports and display their works there as well as Prayer Times according to Tønsberg. We built a multilingual website for them using Blazor Web Assembly."
            btnLink="#"
            btnText="See The Website"
          />
        </motion.div>
        <motion.div variants={portfoliosPageAnimation.portfoliosRightV} className="mt-20 md:mt-0">
          <SinglePortfolio
            imageSource={AmirFialaWebsite}
            alt="Amir Fiala Website Preview"
            title="Amir Fiala - Graphic Designer"
            description="Amir Fiala's E-Commerce WordPress website was hacked, so he contacted us through one of his friends and asked for help. We cleaned and rebuilt his entire website without any loss of data."
            btnLink="https://amirfiala.com"
            btnText="See The Website"
          />
        </motion.div>
        <motion.div variants={portfoliosPageAnimation.portfoliosLeftV} className="mt-20 md:mt-0">
          <SinglePortfolio
            imageSource={LibraryManager}
            alt="Library Manager"
            title="Library Manager"
            description={`Once in College Of Science, Mathematics Department's library, they were writing names of those students who takes books on the paper, the process was so slow and was taking a lot of time to find students name, so we developed a Windows application for them to manage their library much quicker and easier. It's been ${
              new Date().getFullYear() - 2015
            } years that they use our application and they are happy with it.`}
            btnLink="/contact"
            btnText="Request The Application"
          />
        </motion.div>
        <motion.div variants={portfoliosPageAnimation.portfoliosRightV} className="mt-20 md:mt-0">
          <SinglePortfolio
            imageSource={SweetRuya}
            alt="Sweet Ruya Factory"
            title="Sweet Ruya Factory"
            description="Sweet Ruya Factory is a big factory for making sweets, they asked a website to show their products to the customers, so we built the website as required using .NET Core for the backend."
            btnLink="http://sweetruya.com/"
            btnText="See The Website"
          />
        </motion.div>
      </motion.div>
      <p className="mt-12 text-2xl font-bold text-center text-white">
        If you want to see our other projects, then{" "}
        <Link className="text-primary" to="/contact">
          contact us
        </Link>
      </p>
    </motion.section>
  );
}

export default Portfolios;
