import React from "react";
import { motion } from "framer-motion";

function DoubleCircleIcon({ width = "34", height = "50" }) {
  const circleV = {
    initial: {
      rotate: 0,
    },
    animate: {
      rotate: 360,
      transition: {
        duration: 1.4,
        repeat: Infinity,
        repeatType: "loop",
        repeatDelay: 0,
        ease: "linear",
      },
    },
  };

  return (
    <motion.svg
      drag
      dragConstraints={{
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      }}
      variants={circleV}
      initial="initial"
      animate="animate"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <g id="Group_1" data-name="Group 1" transform="translate(-0.152 -0.119)">
        <circle id="Ellipse_2" data-name="Ellipse 2" cx="12.942" cy="12.942" r="12.942" transform="translate(7.784 13.725)" fill="none" stroke="#fff" strokeWidth="0.75" />
        <circle id="Ellipse_1" data-name="Ellipse 1" cx="12.278" cy="12.278" r="12.278" transform="translate(1.147 1.114)" fill="none" stroke="#fff" strokeWidth="2" />
      </g>
    </motion.svg>
  );
}

export default DoubleCircleIcon;
