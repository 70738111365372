import React from 'react';
import { motion } from 'framer-motion';
import { littleContactSectionAnimation } from '../animateProperties';
import { Link } from 'react-router-dom';

function LittleContactSection() {
  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} className="py-24 bg-gray-900">
      <div className="container grid grid-cols-1 mx-auto sm:grid-cols-3">
        <div className="col-span-2 mb-4">
          <motion.h1 variants={littleContactSectionAnimation.headV} className="mb-6 text-2xl font-bold text-center text-white sm:text-left md:mb-4 md:text-3xl">
            Got Intereseted?
          </motion.h1>
          <motion.p variants={littleContactSectionAnimation.paragraphV} className="text-center text-gray-400 sm:text-left ">
            If you have any further questions, feel free to contact us.
          </motion.p>
        </div>
        <motion.div variants={littleContactSectionAnimation.btnV} className="flex items-center justify-center sm:justify-end">
          <Link to="/contact">
            <button className="btn btn-primary">Contact Us</button>
          </Link>
        </motion.div>
      </div>
    </motion.section>
  );
}

export default LittleContactSection;
