import React from "react";
import { motion } from "framer-motion";

function ZigzagLine({width = "64.953" , height = "20.435"}) {
  const zigzagLinePathVariants = {
    initial: {
      pathLength: 0,
    },
    animate: {
      pathLength: 1,
      transition: {
        duration: 1.5,
        repeat: Infinity,
        repeatDelay: 0.3,
        repeatType: "reverse",
      },
    },
  };
  return (
      <motion.svg drag dragConstraints={{ right: 50, left: 50, top: 50, bottom: 50 }} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64.953 20.435">
        <motion.path
          variants={zigzagLinePathVariants}
          initial="initial"
          animate="animate"
          id="Path_1"
          data-name="Path 1"
          d="M1.44,1.312,16.89,17.39,26.494,5.7,37.976,17.39,50.5,5.7,64.491,19.9"
          transform="translate(-0.483 -0.393)"
          fill="none"
          stroke="#e7e7e7"
          strokeWidth="3"
        />
      </motion.svg>
  );
}

export default ZigzagLine;
