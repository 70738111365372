import Header from './components/Header';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Portfolios from './pages/Portfolios';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import BackToTop from './components/BackToTop';
import { useLayoutEffect } from 'react';
import { motion, useScroll } from 'framer-motion';
import Footer from './components/Footer';
import Error404 from './pages/Error404';

const Wrapper = ({ children }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

function App() {
  const { scrollYProgress } = useScroll();

  return (
    <>
      <Router>
        <Wrapper>
          <Header />
          <motion.div className="fixed top-0 left-0 right-0 h-[5px] bg-primary opacity-100 origin-left z-50" style={{ scaleX: scrollYProgress }}></motion.div>
          <BackToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/portfolios" element={<Portfolios />} />
            <Route path="/*" element={<Error404 />} />
          </Routes>
          <Footer />
        </Wrapper>
      </Router>
    </>
  );
}

export default App;
