import React, { useEffect } from "react";
import HeroSection from "../sections/HeroSection";
import OurClientsSection from "../sections/OurClientsSection";
import OurTechnologiesSection from "../sections/OurTechnologiesSection";
import ServicesSection from "../sections/ServicesSection";
import LittleContactSection from "../sections/LittleContactSection";

function Home() {
  useEffect(() => {
    document.title = "Home | GrupyStar Company";
  }, []);

  return (
    <>
      <HeroSection />
      <ServicesSection />
      <OurTechnologiesSection />
      <OurClientsSection />
      <LittleContactSection />
    </>
  );
}

export default Home;
