import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as HeroVerctor } from '../assets/svg/Hero Section Vector.svg';
import { motion } from 'framer-motion';
import { heroSectionAnimation } from '../animateProperties';
import ZigzagLine from '../components/ZigzagLine';
import DoubleCircleIcon from '../components/DoubleCircleIcon';
import DoubleTringleIcon from '../components/DoubleTringleIcon';
import PlusSignIcon from '../components/PlusSignIcon';

function HeroSection() {
  return (
    // <section className="grid min-h-screen grid-cols-1 px-4 py-4 grad-bg md:grid-cols-2 md:px-12 lg:px-20 xl:px-32">
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} className="grad-bg">
      <div className="container grid min-h-screen grid-cols-1 mx-auto md:grid-cols-2">
        <div className="flex flex-col items-center justify-center md:items-start">
          <motion.h1 viewport="viewport" variants={heroSectionAnimation.titleV} className="text-4xl font-bold text-center text-white md:text-left md:text-5xl">
            Take Your Career <div className="block mt-2 text-5xl font-bold text-white md:inline lg:block md:text-6xl"> One Step Ahead</div>
          </motion.h1>
          <motion.p viewport="viewport" variants={heroSectionAnimation.taglineV} className="my-5 text-center text-gray-400 md:text-left">
            GrupyStar is a company that offers several services such as Web Developments, Mobile Applications and UI/UX Design. We will provide the best services for those who use our services.
          </motion.p>
          <motion.div variants={heroSectionAnimation.serviceBtnV}>
            <HashLink to="/#services">
              <motion.button className="btn btn-primary">View Services</motion.button>
            </HashLink>
          </motion.div>
        </div>
        <div className="relative flex items-center justify-end">
          <motion.div variants={heroSectionAnimation.heroVectorV} className="w-full sm:w-11/12">
            <div>
              <HeroVerctor width="100%" />
            </div>
          </motion.div>
          <div className="absolute top-20 left-12">
            <ZigzagLine />
          </div>
          <div className="absolute top-16 right-8">
            <DoubleCircleIcon />
          </div>
          <div className="absolute bottom-16 left-8">
            <DoubleTringleIcon />
          </div>
          <div className="absolute bottom-12 right-4">
            <PlusSignIcon />
          </div>
        </div>
      </div>
    </motion.section>
  );
}

export default HeroSection;
