import React, { useState } from "react";
import { ReactComponent as ArrowCircleUp } from "../assets/svg/arrow-circle-up.svg";

function BackToTop() {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);
  return (
    <ArrowCircleUp
      className="fill-current flex fixed text-white bottom-4 transition-all duration-500 hover:text-primary hover:opacity-100 right-4 items-end h-[40px] justify-end z-50 cursor-pointer"
      onClick={scrollTop}
      style={{ opacity: showScroll ? 0.8 : 0 }}
    />
  );
}

export default BackToTop;
