import React from 'react';
import { motion } from 'framer-motion';
import { ourClientsSectionAnimation } from '../animateProperties';
import ZigzagLine from '../components/ZigzagLine';
import DoubleCircleIcon from '../components/DoubleCircleIcon';
import SingleClient from '../components/SingleClient';

import AmirFiala from '../assets/images/clients/amir-fiala.png';
import BabbulJannah from '../assets/images/clients/babbul-jannah.png';
import FirstTravel from '../assets/images/clients/first-travel.png';
import QndilFactory from '../assets/images/clients/qandil-factory.png';
import DrRawaKarim from '../assets/images/clients/dr-rawa-karim.png';
import SalamMosque from '../assets/images/clients/salam-mosque.png';
import SweetRuya from '../assets/images/clients/sweet-ruya.png';
import TahirKanabi from '../assets/images/clients/Tahir-Kanabi-Company.png';

function OurClientsSection() {
  return (
    <motion.section initial="initial" whileInView="whileInView" viewport={{ once: true }} id="clients" className="container min-h-screen py-16 mx-auto bg-black">
      <div>
        <div className="relative invisible lg:visible">
          <div className="absolute top-4 left-28">
            <DoubleCircleIcon />
          </div>
          <div className="absolute -rotate-45 top-12 right-24">
            <ZigzagLine width={45} height={45} />
          </div>
        </div>
        <motion.h1 variants={ourClientsSectionAnimation.clientsTitleV} className="mb-8 text-4xl font-bold text-center text-white md:mb-12 md:text-5xl">
          Our Clients
        </motion.h1>
      </div>
      <motion.div variants={ourClientsSectionAnimation.parentItemV} className="grid grid-cols-1 mt-24 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-12">
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={AmirFiala} alt="Amir Fiala" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={SweetRuya} alt="Sweet Ruya" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={BabbulJannah} alt="Babul Jannah Sweet Factory" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={TahirKanabi} alt="Tahir Kanabi" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={SalamMosque} alt="Salam Mosque" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={DrRawaKarim} alt="Dr. Rawa Karim" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={QndilFactory} alt="Qandil PVC Factory" />
        </motion.div>
        <motion.div variants={ourClientsSectionAnimation.itemV} className="mx-auto">
          <SingleClient source={FirstTravel} alt="First Travel" />
        </motion.div>
      </motion.div>
    </motion.section>
  );
}

export default OurClientsSection;
